@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

:root {
  --white: #ffffff;
  --orange: #ff6a0d;
  --gray: #7f7f7f;
  --black: #2c2c3a;
  --dark-black: #1f1e25;
  --border: #d9d9d9;
  --16px: 16px;
  --14px: 14px;
  --20px: 20px;
  --25px: 25px;
  --30px: 30px;
  --40px: 40px;
  --60px: 60px;
  --regular: 400;
  --semibold: 600;
  --bold: 700;
}
body {
  font-family: "Space Grotesk" !important;
  margin: 0px;
}

body,
html {
  overflow-x: hidden;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0rem;
  padding-left: 0px;
  list-style: none;
}

img {
  max-width: 100%;
}

header {
  padding: 10px 0px;
  background: var(--dark-black);
  position: fixed;
  z-index: 66;
  width: 100%;
  border-bottom: 1px solid var(--black);
}

.navbar-nav .show .nav-link,
.navbar-nav .nav-link.active {
  color: var(--orange);
  font-size: var(--16px);
  font-weight: var(--bold);
  transform: skewX(0deg);
}

.nav-link.active::before {
  content: "";
  position: absolute;
  right: -13px;
  top: 6px;
  height: 28px;
  width: 2px;
  transform: skewX(-30deg);
  z-index: 1;
  opacity: 1;
  background-image: none;
  bottom: 0;
  border-radius: 0;
  left: auto;
  background-color: var(--orange);
}

/* loader */
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 16px;
  border-radius: 20%;
  background: #ff7d2c;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* loader end */
.nav-link.active::after {
  content: "";
  position: absolute;
  left: -13px;
  top: 6px;
  height: 28px;
  width: 2px;
  transform: skewX(-30deg);
  z-index: 1;
  opacity: 1;
  background-image: none;
  bottom: 0;
  border-radius: 0;
  right: auto;
  background-color: var(--orange);
}

.nav-item {
  padding: 0px 16px;
}
.navbar {
  padding-right: 12px;
}

.nav-link {
  font-size: var(--16px);
  font-weight: var(--regular);
  color: var(--gray);
}

.nav-link:hover,
.nav-link:focus {
  color: var(--orange);
  font-weight: var(--bold);
  transform: skewX(0deg);
}

.btn-black {
  background: var(--black);
  color: var(--white);
  border: none;
  padding: 10px 25px;
  font-size: var(--16px);
  transform: skewX(-30deg);
  transition: all 0.3s ease-out 0s;
}

.btn-black:hover {
  background: var(--orange);
}

.btn-black .text-box {
  transform: skewX(30deg);
}

.btn-black::before {
  content: "";
  position: absolute;
  right: -13px;
  top: 0;
  height: 100%;
  width: 2px;
  transform: skewX(0deg);
  z-index: 1;
  opacity: 1;
  background-image: none;
  bottom: 0;
  border-radius: 0;
  left: auto;
  background-color: var(--orange);
}

.btn-black::after {
  content: "";
  position: absolute;
  left: -13px;
  top: 0;
  height: 100%;
  width: 2px;
  transform: skewX(0deg);
  z-index: 1;
  opacity: 1;
  background-image: none;
  bottom: 0;
  border-radius: 0;
  right: auto;
  background-color: var(--orange);
}

.btn-black:hover::before {
  right: 0;
  animation-delay: 0.8s;
}

.btn-black:hover::after {
  left: 0;
}

.nav-link:hover::before {
  content: "";
  position: absolute;
  right: -13px;
  top: 6px;
  height: 28px;
  width: 2px;
  transform: skewX(-30deg);
  z-index: 1;
  opacity: 1;
  background-image: none;
  bottom: 0;
  border-radius: 0;
  left: auto;
  background-color: var(--orange);
}

.nav-link:hover::after {
  content: "";
  position: absolute;
  left: -13px;
  top: 6px;
  height: 28px;
  width: 2px;
  transform: skewX(-30deg);
  z-index: 1;
  opacity: 1;
  background-image: none;
  bottom: 0;
  border-radius: 0;
  right: auto;
  background-color: var(--orange);
}

.btn-icon {
  width: 30px;
  height: 30px;
  padding-right: 5px;
  border-radius: 50%;
}

.banner {
  background: url(../public/images/banner.jpg);
  height: 1000px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  margin: 0px;
  color: #ffffff;
  padding-top: 150px;
  width: 100%;
}

.banner-text-box {
  text-align: center;
  font-size: var(--60px);
  font-weight: var(--bold);
  margin: 0px;
  padding-top: 100px;
}

.new-btn {
  color: var(--white);
  border: none;
  padding: 10px 25px;
  font-size: var(--16px);
  font-weight: var(--bold);
  transform: skewX(-30deg);
  transition: all 0.3s ease-out 0s;
  background-image: linear-gradient(to right, #ff900d, #ff6a0d);
}

.new-btn:hover {
  background: var(--white);
  color: var(--orange);
}

.new-btn .text-box {
  transform: skewX(30deg);
}

.new-btn::before {
  content: "";
  position: absolute;
  right: -13px;
  top: 0;
  height: 100%;
  width: 2px;
  transform: skewX(0deg);
  z-index: 1;
  opacity: 1;
  background-image: none;
  bottom: 0;
  border-radius: 0;
  left: auto;
  background-color: var(--orange);
}

.new-btn::after {
  content: "";
  position: absolute;
  left: -13px;
  top: 0;
  height: 100%;
  width: 2px;
  transform: skewX(0deg);
  z-index: 1;
  opacity: 1;
  background-image: none;
  bottom: 0;
  border-radius: 0;
  right: auto;
  background-color: var(--orange);
}

.new-btn:hover::before {
  right: 0;
  animation-delay: 0.8s;
}

.new-btn:hover::after {
  left: 0;
}

.tf .orange-txt {
  color: var(--orange);
}

.tf h6 {
  font-size: var(--60px);
  font-weight: var(--bold);
  margin: 0px;
  padding: 0px;
}

.tf h4 {
  font-size: var(--25px);
  font-weight: var(--bold);
  color: var(--black);
  margin: 0px;
  padding-top: 40px;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.tf p {
  margin: 0px;
  padding-top: 50px;
  font-size: var(--16px);
  color: var(--gray);
  font-weight: var(--regular);
}

.tf {
  padding-top: 100px;
}

.Multiple-Sports-card .card {
  border: 1px solid var(--border);
  padding: 40px 80px;
  border-radius: 0px;
  transform: skewX(-16deg);
}

.Multiple-Sports-card .inner-card p {
  margin: 0px;
  padding-top: 25px;
  font-size: var(--16px);
  color: var(--gray);
  font-weight: var(--regular);
}

.inner-card {
  transform: skewX(16deg);
}

.padding-right {
  padding-right: 0px;
}

.Multiple-Sports-card .card:hover {
  text-decoration: none;
  background: var(--black);
  color: var(--white);
  transition: all 0.3s ease-out 0s;
}

.Multiple-Sports-card .card:hover svg path {
  fill: #ffffff;
}

.Multiple-Sports-card .card:hover circle {
  fill: var(--gray);
  stroke: var(--gray);
  stroke-opacity: 0.4;
  fill-opacity: 0.1;
}

.Multiple-Sports-card .card:hover h4 {
  font-size: var(--25px);
  font-weight: var(--bold);
  color: var(--white);
  margin: 0px;
}

.Multiple-Sports-card .icon {
  width: 100px;
  height: 100px;
}

.tf h3 {
  font-size: var(--30px);
  font-weight: var(--regular);
  padding-bottom: 40px;
  margin: 0px;
}

.Multiple-Sports {
  padding-bottom: 100px;
}

.bg-img {
  background: url(../public/images/section-bg.png);
  /* height: 1550px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  margin: 0px;
  color: #ffffff;
  width: 100%;
  padding: 0px;
  margin-bottom: 100px;
}

.padding-b-50px {
  padding-bottom: 50px;
}

.tf .redeem-your-points {
  text-align: center;
  padding-bottom: 120px;
}
.tf .redeem-your-points .row.g-0 {
  position: absolute;
  bottom: -271px;
}
.redeem-your-points .card {
  border: 1px solid var(--border);
  border-radius: 0px;
  transform: skewX(-16deg);
  padding: 50px 20px;
  display: flex;
  max-height: 255px;
}

.redeem-your-points .inner-card {
  transform: skewX(16deg);
  margin: auto;
}

.tf h2 {
  font-size: var(--20px);
  font-weight: bold;
  color: var(--black);
}

.redeem-your-points .text-box {
  padding-top: 25px;
}

.redeem-your-points .card:hover {
  text-decoration: none;
  background: var(--black);
  color: var(--white);
  border: none;
  transition: all 0.3s ease-out 0s;
}

.redeem-your-points .card:hover h2 {
  text-decoration: none;
  color: var(--white);
  border: none;
}

.Testimonials .text-box {
  text-align: center;
  color: var(--black);
}

footer {
  background: var(--dark-black);
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  color: var(--white);
  padding: 0px;
  width: 100%;
  padding-top: 100px;
}

footer h1 {
  font-size: var(--16px);
  font-weight: var(--bold);
  color: var(--white);
  padding-bottom: 35px;
}

footer ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  justify-content: flex-start;
}

footer ul li a {
  text-decoration: none;
  font-size: var(--16px);
  line-height: 30px;
  color: var(--gray);
  font-weight: 400;
  padding: 0px;
}

footer ul li a:hover,
a:focus {
  color: var(--orange);
}
footer ul li a:active {
  color: var(--orange);
}

footer .form-control {
  display: block;
  width: 100%;
  padding: 10px 15px;
  font-size: var(--16px);
  color: #fff;
  background-color: var(--dark-black);
  background-clip: padding-box;
  border: 1px solid var(--border);
  margin-right: 0px !important;
  border-radius: 0px;
}

footer .btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgb(40 167 69 / 0%);
}

footer .form-control:focus {
  color: var(--orange);
  background-color: var(--dark-black);
  border-color: var(--orange);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}

footer .d-flex {
  justify-content: center;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

footer .btn-outline-success {
  color: var(--green);
  background-color: #ffffff;
  background-image: none;
}
.team_logo_border.active {
  border: 1px solid #ff6a0d;
}
footer .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(40 167 69 / 0%);
}
footer ul li button {
  text-decoration: none;
  font-size: var(--14px);
  line-height: 20px;
  color: var(--gray);
  font-weight: 400;
  padding: 0px;
  background: none;
  border: none;
}
input[type="text"] {
  color: var(--black);
}

footer .button a {
  background: var(--orange);
  padding: 9px 22px;
  text-align: center;
  border-radius: 0px;
  border: 1px solid var(--black);
  text-decoration: none;
  display: inline-block;
  color: var(--white);
  font-size: var(--16px);
  font-weight: 400;
  margin: 15px 0px;
  text-transform: uppercase;
}

footer .copy-right ul {
  margin: 0px;
  padding-top: 40px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
}

footer .copy-right ul li a {
  text-decoration: none;
  font-size: var(--16px);
  color: var(--gray);
  font-weight: 400;
  padding: 0px 10px;
}

footer .copy-right p {
  padding: 25px 0px;
  color: var(--gray);
  font-size: var(--16px);
  font-weight: var(--regular);
  text-align: center;
}

footer .text-1 {
  padding-left: 100px;
}

.text-1 ul li {
  padding-bottom: 20px;
}

.footer-form a {
  background: var(--white);
  padding: 18px;
  display: flex;
  cursor: pointer;
}

.footer-form a:hover,
.footer-form a:focus-visible {
  background: var(--orange);
  outline: 0;
}

.bg-white-img {
  background: url(../public/images/bg-white-img.png);
  height: 1550px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  margin: 0px;
  color: #ffffff;
  width: 100%;
  padding: 0px;
}
/* new style */
@font-face {
  font-family: "heyaugust";
  src: url("../public/fontFamily/hey_august.woff") format("opentype");
}
.notification_box h5 {
  text-transform: capitalize;
}
.container {
  max-width: 1218px;
}
P {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}
.color_yellow {
  color: #ff6a0d;
}
.bold_text {
  font-weight: 700;
  font-size: 12px;
  color: #1f1e25;
}
.h1_30 {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #1f1e25;
}
.text_normal {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  color: #1f1e25;
}
a.text_normal {
  text-decoration: none;
}
.text_green {
  color: #1bbf00;
}
.normal_p {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.boldP {
  font-weight: 700;
  color: #000000;
}
.textGRY700 {
  font-weight: 700;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}
.text20black {
  font-weight: 500;
  font-size: 20px;
  color: #1f1e25;
}
.Toastify__toast-theme--light {
  background: linear-gradient(90deg, #1f1e25 0%, #1f1e25 100%) !important;
}
.Toastify__toast-body > div:last-child {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.Toastify__close-button > svg {
  fill: #fff !important;
}
.text20black700 {
  font-weight: 700;
  font-size: 20px;
  color: #1f1e25;
}
.manully_hr {
  background-color: #e1e1e1;
}
.P_gry_normal {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}
.P_gry_normal p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}
.H5_bold_black {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #1f1e25;
}
.form-select:focus,
.form-control:focus {
  box-shadow: none;
  border-color: rgb(224, 211, 211);
}
input:focus-visible {
  border: 0 !important;
  outline: 1px solid #ff6a0d !important;
}
.table > :not(caption) > * > * {
  padding: 24px 8px;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  vertical-align: middle;
}
.table thead {
  border: 1px solid #e1e1e1;
}
.table thead tr th:first-child,
.table tbody tr td:first-child {
  padding-left: 30px;
}
.table thead tr th {
  font-size: 16px;
  line-height: 20px;
  color: #1f1e25;
  text-align: center;
  text-transform: capitalize;
}
.table tbody tr td {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
}
td.value_up {
  color: #1bbf00 !important;
}
td.value_down {
  color: #ff3535 !important;
}
.form-select {
  padding: 10px;
}
.btn {
  border: 1px solid #7f7f7f;
  background-color: transparent;
  color: #000000;
  padding: 6px 12px;
}
.product_option :is(.btn:hover, .btn:focus, .btn:target) {
  border: 1px solid #ff6a0d;
  background-color: transparent;
  color: #ff6a0d;
}
.btn:hover,
.btn:focus,
.btn:target {
  border: 1px solid #7f7f7f;
  background-color: transparent;
  color: #000000;
}
.ad_section {
  margin: 50px 0;
}
.ad_section.ad_top {
  margin: 25px 0;
}
.input,
.input:hover,
.input:focus {
  border: 1px solid #cad2d9;
  background: transparent;
  color: #1f1e25;
  padding: 20px 14px;
}
.post_btn.fly_btn {
  background: #ff6a0d;
  transform: skew(0deg);
  height: 67px;
}
.model_hr {
  max-width: 1066px;
  list-style-type: disc;
}
/* btns */
.post_btn {
  width: 199px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ff6a0d;
  border-radius: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  transform: skew(-9deg);
  color: #ff6a0d;
  background: linear-gradient(to right, #ff6a0d 50%, white 50%);
  margin: auto;
}
.post_btn:hover {
  background-position: left bottom;
  color: white;
}
.btnFill {
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
}

.btnFill:hover {
  background-position: left bottom;
  border: 1px solid #ff6a0d;
}

.btnfilltext {
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  color: #ff6a0d;
  transition: all 0.1s ease-out;
  display: block;
}
.btnFill:hover .btnfilltext {
  color: white;
}
.post_btn.smallbtn {
  padding: 20px;
  width: auto;
}
.post_btn.smallbtn.green_btn {
  background: linear-gradient(to right, #1bbf00 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  border: 1px solid #1bbf00;
}
.post_btn.smallbtn.green_btn .btnfilltext {
  color: #1bbf00 !important;
}
.post_btn.smallbtn:hover .btnfilltext {
  color: #fff !important;
}
.post_btn.smallbtn.green_btn:hover {
  background-position: left bottom;
}
.post_btn.smallbtn.gry_btn {
  background: linear-gradient(to right, #00000080 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  border: 1px solid #00000080;
}
.post_btn.smallbtn.gry_btn .btnfilltext {
  color: #00000080 !important;
}
.post_btn.smallbtn.gry_btn:hover {
  background-position: left bottom;
}
.post_btn.smallbtn.red_btn {
  background: linear-gradient(to right, #ff2222 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  border: 1px solid #ff2222;
}
.post_btn.smallbtn.red_btn .btnfilltext {
  color: #ff2222 !important;
}
.post_btn.smallbtn.black_btn {
  background: linear-gradient(to right, #000 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  border: 1px solid #000;
}
.post_btn.smallbtn.black_btn .btnfilltext {
  color: #000 !important;
}
.post_btn.smallbtn:hover .btnfilltext {
  color: #fff !important;
}
.post_btn.smallbtn.red_btn:hover {
  background-position: left bottom;
}
.post_btn.smallbtn.black_btn:hover {
  background-position: left bottom;
}
/* Profile start */
#Profile {
  padding: 50px 0;
  background-color: #1f1e25;
}
.profile_section_left {
  display: flex;
  column-gap: 30px;
}
.profile_dp {
  width: 150px;
  height: 150px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.profile_detail {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.profile_detail h1 {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #e6e6e6;
}
.profile_detail p {
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
}
.profile_detail p span,
.profile_section_right p {
  font-weight: 700;
  color: #ffffff;
}
.profile_section_right {
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}
a.edit_profile {
  font-weight: 700;
  font-size: 16px;
  color: #ff6a0d;
  text-decoration: none;
}
.profile_section_right p span {
  color: #ffffff;
}
p.balence_p {
  margin-bottom: 3px;
  margin-top: 30px;
}
/* Tabs_section */
#Tabs_section,
.early_posts {
  padding: 0;
  background-color: #fff;
}
.tabs_nav {
  max-width: 1000px;
  margin: auto;
}
.tabs_nav,
.bats_nav {
  display: flex;
  justify-content: center;
}
ul#tabs {
  display: flex;
  margin-top: 25px;
}
ul#tabs li:first-child {
  border-left: 1px solid #e1e1e1;
}
ul#tabs li {
  border-top: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding: 20px 34px;
  position: relative;
  z-index: 999;
  min-width: 150px;
  text-align: center;
  transform: skew(-17deg, 0deg);
}
ul#tabs li div {
  transform: skew(17deg, 0deg);
}
ul#tabs li div a {
  color: #1f1e25;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
}
ul#tabs li.active_tab,
ul#tabs li.active_tab div a {
  background-color: #ff6a0d;
  color: #fff;
}

/* tebs_content */
.theWall {
  margin-top: 40px;
}
.tebs_content h1 {
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  color: #1f1e25;
}
.add_post {
  margin-bottom: 50px;
}
.post_detail {
  margin-top: 50px;
}
.selected_images {
  margin-bottom: 50px;
}
.add_post input {
  width: 100%;
}
.SelectImg {
  display: flex;
  position: relative;
  align-items: center;
  min-height: 67px;
  border: 1px solid #00000026;
}
.input {
  width: 100%;
  min-height: 67px;
  border: 1px solid #00000026;
}
.SelectImg .input {
  width: 30%;
  width: 30%;
  min-height: 67px;
  border: 1px solid #00000026;
  opacity: 0;
  position: relative;
}
.SelectImg label {
  position: absolute;
  left: 22px;
}
p.selected_file {
  border-left: 0;
  width: 70%;
  color: #7f7f7f;
  position: relative;
}
p.selected_file::before {
  content: "";
  position: absolute;
  left: -15px;
  top: -21px;
  height: 67px;
  width: 1px;
  background-color: #00000026;
}
.selected_images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 15px;
  margin: 20px 0;
}
.select_img {
  position: relative;
}
.select_img .img_options {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  column-gap: 10px;
  opacity: 0;
  transition: 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}
.select_img:hover .img_options {
  opacity: 1;
}
.post_imagge {
  width: 100%;
  height: 100%;
}
.select_img .post_imagge {
  max-height: 185px;
  min-width: 184.05px;
  min-height: 184.05px;
  max-width: 185px;
  object-fit: cover;
}

.post_container:first-child {
  border-top: 0px solid #00000026;
  /* padding: 50px 0; */
}
#wall .post_container:first-child {
  border-top: 1px solid #00000026;
  /* padding: 50px 0; */
}
.post_container {
  padding: 50px 0;
  border-top: 1px solid #00000026;
  margin-bottom: 30px;
}
.friend_list.post_container {
  padding: 20px 0 !important;
  border-top: 0px solid #00000026;
  border-bottom: 1px solid #00000026;
}
.early_posts .post_container:last-child {
  padding-bottom: 0;
}
.post_container p {
  color: #000000;
}
.poster_img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: auto;
}
.poster_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user_image .user_image {
  max-width: 68px;
  max-height: 68px;
  min-width: 67px;
  object-fit: cover;
  min-height: 67px;
  border-radius: 50%;
}
p.poster_name {
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}
p.poster_name span {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 15px;
}
p.date_time {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 8px;
}
.poster_images {
  margin-top: 8px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.sports_img.sports_img1 img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* wallet_profile start */
.wallet_profile {
  padding: 30px 50px;
  background: url(../public/images/wallet_profile_bg.png) no-repeat;
  margin: 50px 0;
}
.Withdraw_row {
  display: flex;
  justify-content: center;
  column-gap: 20px;
}
.Withdraw_row .post_btn {
  margin: 0;
}
.wallet_profiler_detail {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
.profile_dots {
  width: 197px;
  height: 5px;
  margin-bottom: 8px;
}
.wallet_profile h1 {
  font-weight: 500;
  font-size: 40px;
  color: #ffffff;
  text-align: start;
}
.wallet_profile h2 {
  font-weight: 700;
  font-size: 30px;
  color: #ffffff;
}
.wallet_profile h2.active {
  font-size: 18px !important;
}
.wallet_proflie_boxs {
  display: flex;
  justify-content: space-between;
}
.wallet_profile_box {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  justify-content: center;
  align-items: center;
}
.withdraw_icon {
  margin-right: 10px;
}
.btnFill:hover .withdraw_icon path {
  fill: #fff;
}
.tebs_content h5 {
  font-weight: 700;
  font-size: 20px;
  color: #1f1e25;
}
.recent_transitions {
  margin: 50px 0;
}
.transition_table {
  margin-top: 19px;
}
.recent_transition p {
  color: #ff6a0d;
}
ul#bets {
  max-width: 450px;
}
.winner_user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
ul#friends_tabs {
  max-width: 895px;
}
ul#Setting_tabs {
  max-width: 370px;
}
.bats_nav ul#edit_tabs {
  max-width: 230px;
  padding: 0 20px;
}
.bats_nav ul {
  margin: 30px auto;
  display: flex;
  justify-content: center;
  column-gap: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0 30px;
}
.bats_nav ul li div {
  padding-bottom: 15px;
}
.bats_nav ul li a {
  color: #1f1e25;
  text-decoration: none;
  text-transform: uppercase;
}
.bats_nav ul li.active_bat {
  border-bottom: 2px solid #ff6a0d;
}
.bats_nav ul li.active_bat a {
  color: #ff6a0d;
  font-weight: 700;
}
.balence_col {
  display: flex;
  column-gap: 20px;
}
.balence_box h2 {
  font-weight: 700;
  font-size: 30px;
  color: #1f1e25;
}
.balence_box p {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
}
.matches_history {
  border: 1px solid #e1e1e1;
  margin: 50px 0;
}
.row.dropdowns {
  padding: 25px;
}
.upcoming_matches_list {
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding: 25px;
}
.upcoming_matches_table {
  padding: 25px 25px 0 25px;
}
.upcoming_matches_table .table thead tr th:first-child,
.upcoming_matches_table .table tbody tr td:first-child {
  padding-left: 10px;
}
.upcoming_matches_table .table thead tr th:nth-child(2),
.upcoming_matches_table .table tbody tr td:nth-child(2) {
  text-align: start;
}
.upcoming_matches_table .table tbody tr td:nth-child(7) {
  color: #1bbf00;
  font-weight: 600;
}
.upcoming_matches_table .table tbody tr td:last-child {
  color: #ff6a0d;
  font-weight: 600;
}
.table h5 {
  font-weight: 700;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}
.table p {
  font-size: 12px;
  color: #7f7f7f;
}
/* awards_container */
.awards_container {
  display: flex;
  column-gap: 0;
  margin: auto;
  row-gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 50px;
  justify-content: center;
}
.Product_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #7f7f7f;
  /* border-left: 0px solid #7f7f7f; */
  padding: 5px;
  width: 25%;
  transition: all 0.3s ease-in-out;
}
/* .Product_box:first-child {
  border-left: 1px solid #7f7f7f;
} */
.Product_box p {
  color: #6a6a6a;
}
.Product_box h5 {
  text-align: center;
  margin-top: 53px;
}
.product_img {
  padding: 25px 0 10px;
  /* width: 264px; */
  height: 227px;
  object-fit: contain;
}
.product_option {
  justify-content: space-between;
  width: 100%;
  display: none;
  margin-top: 15px;
}
.detail_btn {
  border: 0;
  color: #ff6a0d;
}
.Apply_btn {
  border: 1px solid #ff6a0d;
  color: #ff6a0d;
}
.product_option .btn:hover,
.product_option .btn:focus,
.product_option .btn:target {
  color: #ff6a0d;
}
.Product_box:hover {
  background-color: #2c2c3a;
}
.Product_box:hover .product_option {
  display: flex;
}
.Product_box:hover h5 {
  color: #fff;
  margin-top: 0;
}
/* BETS SECTION */
#Tabs_section.Bats_tab {
  padding: 20px 0 30px 0;
}
.h1_60 {
  font-weight: 700;
  font-size: 60px;
  color: #1f1e25;
  text-align: center;
}
ul#Sports_tabs {
  max-width: 577px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  column-gap: 17px;
}
.sports_active_show div {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;
}
.sports_img {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  border-radius: 50%;
}
.sports_img1 {
  background-color: rgba(255, 53, 53, 0.15);
}
.sports_img2 {
  background-color: rgba(229, 126, 37, 0.15);
}
.sports_img3 {
  background-color: rgba(90, 51, 146, 0.15);
}
.sports_img4 {
  background-color: rgba(175, 206, 0, 0.15);
}
ul#Sports_tabs li div svg {
  width: 40px;
  height: 40px;
}
.sports_active_show div a {
  color: #8f8e92;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
}
.sports_active_show.sports_active a {
  font-weight: 600;
  font-size: 16px;
  color: #ff6a0d;
}
.sports_active_show.sports_active div .sports_img {
  background-color: #2c2c3a;
}
/* .sports_active_show.sports_active div .sports_img path {
  fill: #ffffff;
} */
.sports_active_show.sports_active div .sports_img path.fill_color {
  fill: #ffffff;
}
.betClosed {
  width: 144px;
  margin: auto;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(31, 30, 37, 0.5);
  border-radius: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  transform: skew(-9deg);
  color: rgba(31, 30, 37, 0.5);
}
.btnFill.betbtn {
  width: 144px;
  height: 40px;
}
.betClosed:hover {
  border: 1px solid rgba(31, 30, 37, 0.5);
  color: rgba(31, 30, 37, 0.5);
}
/* modal */
.btn-close {
  position: absolute;
  top: 13px;
  right: 17px;
  background: url(../public/images/close_btn.png) no-repeat !important;
  width: 25px;
  height: 25px;
  opacity: 1;
  padding: 0px;
  z-index: 999;
}
.btn-close:focus-visible {
  outline: 0;
  border: 0;
}
.modal h1 {
  font-weight: 700;
  font-size: 40px;
  color: #1f1e25;
  text-align: center;
}
.modal#betHere {
  /* max-width: 500px; */
  margin: auto;
}
.modal-content {
  padding: 25px;
}
.match_detail {
  padding: 25px;
  border: 1px solid #d9d9d9;
  margin-top: 50px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}
.bet_action {
  padding: 25px;
  border: 1px solid #d9d9d9;
}
.nav-link.select {
  background: transparent;
  color: #fff;
  outline: none;
  border: none;
}
.nav-link.select option {
  color: #000;
}
.team_content {
  display: flex;
  justify-content: space-between;
}
.team_box {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.team_box :is(p, h5) {
  color: #1f1e25;
  font-size: 14px;
}
.team_box :is(p, h5).active {
  color: #ff6a0d;
}
.team_logo {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;
  border-radius: 50%;
}
.team_logo img {
  width: 68.42px;
  height: 68.42px;
}
.match_contest,
.bet_amount {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
}
.contect_detail {
  width: 30%;
  display: flex;
  padding: 10px;
  height: 60px;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
  background: #f6f6f6;
}
.post_btn.bet_btn {
  width: 48%;
}
.bet_amount .input {
  width: 79%;
}
.bet_amount .btn {
  width: 19%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .modal-dialog {
  top: 5%;
} */
#Restricted .modal-dialog {
  max-width: 1200px;
  margin: auto;
}
/* withdraw_modal */
.withdraw_modal img {
  margin: 50px 0;
}
.withdraw_modal .textGRY700 {
  font-weight: 400;
  margin-bottom: 20px;
}
/* friends */
.Open_menu {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 150px;
}
.Open_menu .menu_option {
  display: flex;
  column-gap: 15px;
  align-items: center;
}
.post_delete {
  position: relative;
}
.Open_menu a {
  color: #1f1e25 !important;
  text-decoration: none;
}
#OpenMenu {
  position: absolute;
  opacity: 0;
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
  transition: 0.5s;
  visibility: hidden;
  right: 50px;
  z-index: 99;
}
#OpenMenu.active {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
}
.no_users {
  text-align: center;
  padding: 30px 0;
}
.form_container form .row div {
  margin-bottom: 20px;
  padding: 0 30px;
}
.form_container form .row p.boldP {
  margin-bottom: 10px;
}
.form_container form .row div.col-md-6 {
  margin-bottom: 20px;
  padding: 0 20px;
}
.form_container form .row div.col-md-6 div {
  margin-bottom: 0px;
  padding: 0 0px;
}
.form_container .input {
  min-height: 60px;
}
.Label {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #1f1e25;
}
.form_container .post_btn.smallbtn {
  width: 135px;
  margin: 20px 0 0 10px;
}
.social_content {
  margin: 40px 0 0 5px;
}
.social_sites {
  display: flex;
  column-gap: 10px;
  margin-top: 30px;
}
.social_sites .post_btn {
  margin: 0;
}
.notification_box h5 {
  margin-bottom: 25px;
}
input:checked {
  background-color: red;
}
.checkboxs {
  padding-left: 15px;
}
.radio {
  display: flex;
  column-gap: 50px;
  padding-left: 15px;
}
.checkbox_flield {
  margin-bottom: 10px;
}
.control--checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 18px;
}
.control--checkbox span {
  margin-left: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #1f1e25;
}
.control__indicator {
  position: absolute;
  top: 0px;
  left: 0;
  height: 25px;
  width: 25px;
  background: #fff;
  border: 1px solid #ff6a0d;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control input:checked ~ .control__indicator {
  background: #ff6a0d;
}
.control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control .control__indicator:after {
  left: 7px;
  top: 2px;
  width: 9px;
  height: 15px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.control--radio .control__indicator {
  position: absolute;
  top: 7px;
  left: 4px;
  height: 15px;
  width: 15px;
  background: #fff;
  border: 1px solid #ff6a0d;
}
.control--radio input:checked ~ .control__indicator {
  background: #ff6a0d;
  outline-offset: 1px;
  outline: 1px solid #bfbfbf;
}
.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  width: 0;
  height: 0;
  border: 0 solid #fff;
  border-width: 0 0px 0px 0;
  transform: rotate(45deg);
}
.control--radio .control__indicator:after {
  display: none;
}
.control--checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.submit_box .post_btn {
  margin: 0 0 0 15px;
  width: 135px;
}
/* edit_profile */
.edit_profile_photo {
  max-width: 150px;
  margin: 0 auto 50px;
  position: relative;
}
.delete_profile_photo {
  position: absolute;
  right: -12px;
  bottom: 93px;
}
.edit_profile_photo_icon {
  position: absolute;
  right: -10px;
  bottom: 23px;
  cursor: pointer;
}
.edit_profile_photo_icon i {
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff6a0d;
  border: 1px solid #ff6a0d;
  color: #ecdbdb;
  border-radius: 50%;
}
.delete_profile_photo i {
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff6a0d;
  border: 1px solid #ff6a0d;
  color: #ecdbdb;
  border-radius: 50%;
}
.profile_photo {
  border: 1px solid #ff6a0d;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  position: relative;
}
/* .profile_photo::after {
  content: "";
  position: absolute;
  background: url(../public/images/edit_profile_icon.png) no-repeat;
  width: 39px;
  height: 39px;
  bottom: 21px;
  right: -11px;
  z-index: 9999999;
} */
.profile_photo .profile_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.labels {
  position: absolute;
  right: 14px;
  display: flex;
  column-gap: 25px;
  top: 18px;
}
.labels .control--checkbox {
  padding-left: 15px;
}
select.input {
  margin: 0 !important;
  color: rgba(0, 0, 0, 0.5);
}
select.input option {
  background-color: #fff;
  width: 100%;
  padding: 0;
}
/* home page */
.profile_box_responsive {
  display: none;
}
#profileOpen,
#profileOpenn {
  position: absolute;
  opacity: 0;
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
  transition: 0.5s;
  visibility: hidden;
  top: 50px;
  right: 15px;
  z-index: 99;
}
#profileOpen.Open_menu,
#profileOpenn.Open_menu {
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 181px;
}
#profileOpen.active,
#profileOpenn.active {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
}
#profileOpen.Open_menu a,
#profileOpenn.Open_menu a {
  color: rgba(0, 0, 0, 0.5);
}
#profileOpen svg,
#profileOpenn svg {
  width: 25px;
  height: 25px;
}
#profileOpen i,
#profileOpenn i {
  font-size: 25px;
  color: rgba(0, 0, 0, 0.5);
}
#profileOpen.Open_menu .menu_option:hover :is(i, svg path, p, a),
#profileOpenn.Open_menu .menu_option:hover :is(i, svg path, p, a) {
  font-weight: 500;
  color: #ff6a0d;
  fill: #ff6a0d;
}
/* terms and condition and privacy and policy  */
.content-container {
  margin-bottom: 50px;
  padding: 0 30px;
}
.content-container ul {
  list-style-type: decimal;
}
.content-container ul li ul {
  list-style-type: none;
}
.content-container ul li ul li:first-child {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
.content-container ul:first-child li:first-child ul {
  list-style-type: none;
}
.content-container ul:first-child li {
  font-weight: 700;
  font-size: 20px;
  color: #1f1e25;
  margin-bottom: 20px;
}
.content-container ul li ul:first-child li {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
.content-container ul:first-child li ul:first-child {
  margin-top: 16px;
}
.content-container ul:first-child li ul:first-child li ul:first-child {
  margin-top: 0px;
}
.content-container ul:first-child li ul li ul:first-child {
  list-style-type: none;
}
.content-container ul:first-child li ul.big_ul {
  list-style-type: decimal;
}
.content-container ul:first-child li ul.big_ul li {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
.content-container ul:first-child li ul.big_ul li:last-child ul:first-child {
  list-style-type: none !important;
}
.content-container ul:first-child li ul.big_ul li ul:first-child li {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
.content-container
  ul:first-child
  li
  ul:first-child
  li:last-child
  ul:first-child {
  list-style-type: disc;
}
.content-container
  ul:first-child
  li
  ul:first-child
  li:nth-child(2)
  ul:first-child
  li {
  margin-bottom: 0;
}
.content-container ul:first-child li ul li ul:first-child li::marker {
  color: #000;
  background-color: #000;
}

/* login-container */
.Login_body {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#login {
  padding: 0 15px;
}
.login-container {
  max-width: 508px;
  margin: auto;
  max-height: 662px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}
.login-container h1 {
  font-size: 30px;
}
.login_right,
.login_right img {
  height: 100%;
  object-fit: cover;
}
.form-group.floating {
  display: flex;
  position: relative;
  padding: 3px;
  align-items: center;
}
.form-group.floating > input {
  border-radius: 0 !important;
}
.login-container .form-control,
.login-container form {
  width: 100% !important;
}
.form-group.floating > label {
  left: 35px;
}
.form-group.floating > label {
  left: 35px;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  background-color: transparent;
  padding: 0px 5px 0px 5px;
  font-size: 1.1em;
  transition: 0.1s;
  pointer-events: none;
  font-weight: 500 !important;
  transform-origin: bottom left;
  background: #fff !important;
}
.form-control.floating:focus ~ label {
  transform: translateY(-36px) scale(0.8);
  opacity: 1;
  color: #ff6a0d;
  box-shadow: none;
}
.form-control.floating:valid ~ label {
  transform: translateY(-36px) scale(0.8);
  opacity: 1;
  box-shadow: none;
}
.login-container .post_btn {
  width: 100%;
  transform: none;
  color: #1f1e25;
  border: 1px solid #00000026;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 50%, white 50%);
  transition: all 0.5s ease-out;
  background-size: 200% 100%;
  background-position: right bottom;
  padding: 6px;
}
.login-container .post_btn .btnfilltext {
  color: #1f1e25;
}
.login-container .post_btn:hover .btnfilltext {
  color: #fff;
  background-position: left bottom;
}
.login-container .post_btn:hover {
  background-position: left bottom;
}
.login-container .input.submit_input {
  background-color: #ff6a0d;
  color: #fff;
}
.post_btn:focus {
  border: 1px solid #ff6a0d !important;
}
ul.pagination.display.mt-3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination li {
  margin: 0 10px;
}
.pagination .page-item .page-link {
  width: 40px;
  height: 40px;
  border-radius: 15px;
  /* border: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #828282;
  cursor: pointer;
}
.pagination .previous .page-link,
.pagination .next .page-link {
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  width: 100px !important;
  height: 40px;
  display: flex;
  justify-content: center;
}
.pagination .page-item .page-link.active {
  background: #ff6a0d;
  color: #fff;
}
.pagination .page-item.previous .page-link.active,
.pagination .page-item.next .page-link.active {
  color: #ff6a0d;
  background-color: transparent !important;
  border: 1px solid #ff6a0d;
  border-radius: 15px;
}
a.forgetpasword {
  text-decoration: none;
  position: absolute;
  right: 4px;
  bottom: -20px;
  font-weight: 500;
  font-size: 16px;
  color: #ff7d2c;
}
.input_icon {
  position: absolute;
  right: 24px;
  bottom: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.textspaceAdd {
  margin-bottom: -12px;
}
.code_box {
  width: 25px;
  height: 25px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.code .capcha {
  width: 150px;
  height: 40px;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #ff7d2c;
  letter-spacing: 3px;
  background: #d9d9d9;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 25px;
}
.code .refresh_icon {
  position: absolute;
  right: 0px;
  top: 12px;
}
.control--checkbox.register {
  margin-bottom: 0px;
}
/* Testimonial_section */
section.section {
  padding: 100px 0;
}
.section_heading {
  position: relative;
  width: fit-content;
  margin: auto;
}
.section_heading h2 {
  font-family: "heyaugust";
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  color: #ff6a0d;
}
.section_heading .heading_line {
  position: absolute;
  right: -110%;
  width: 90%;
  height: 1px;
  top: 50%;
  background: #ff6a0d;
}
.section h1 {
  font-weight: 700;
  font-size: 60px;
  line-height: 77px;
  color: #1f1e25;
}
.testimonial_row {
  min-height: 366px;
}
.first_slide_content {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}
.column_icon {
  width: 30px;
  height: 30px;
}
.testimonial_user h3 {
  font-weight: 700;
  font-size: 25px;
  color: #000000;
  margin: 0;
  padding: 0;
  margin-top: 0;
}
.section_head {
  margin: 50px 0;
}
.testimonial_user p {
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
p.testimonial_p {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}
.testimonial_arrows {
  position: absolute;
  right: 25px;
  width: 125px;
  height: 71px;
  bottom: -5px;
}
.swiper-button-next::after,
.swiper-button-prev::after,
.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}
.second_slide_content img {
  width: 231px;
  height: 291px;
  transition: all 0.5s ease-in-out;
}
#Testimonial_section .swiper-slide {
  pointer-events: none;
}
.swiper-slide.swiper-slide-active .second_slide_content img {
  height: 350px;
  transition: all 0.5s ease-in-out;
  object-fit: cover;
}

.poster_images .poster_upload_img {
  max-width: 177px;
  max-height: 177px;
  min-width: 177px;
  object-fit: cover;
  min-height: 177px;
}
/* winners_box  */
.winners_box {
  position: relative;
  width: 232px;
  height: 312px;
  background: transparent url(../public/images/winner_boder.png) no-repeat
    center;
  margin: auto;
}
.winner_content h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #1f1e25;
}
.winner_content {
  position: relative;
  top: -13px;
}
.winner_content p.testimonial_p {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  max-width: 224px;
  margin-bottom: 13px;
}
.winnerss_box {
  width: 148px;
  height: 148px;
  position: relative;
  margin: auto;
  background: transparent url(../public/images/winners_img.png) no-repeat;
}
.winner_user {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  margin: 10px auto;
  width: 76px;
  height: 92px;
  position: relative;
  margin: auto;
  top: 27px;
}
/* gamebegin_row */
#gameBegin_section {
  padding: 0;
}
.gamebegin_row {
  background: url(../public/images/CTA.png) no-repeat;
  background-size: cover;
  padding: 0 10px;
}
.gameBegin {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.gameBegin .section_head {
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  color: #ffffff;
}
.gameBegin .testimonial_p {
  color: #ffffff;
}
.gameBegin .btn-black {
  background-color: #ff6a0d;
  width: 221px;
  margin-left: 21px;
}
.gameBegin .section_head {
  margin: 14px 0;
}
/* Footer */
/* footer {
  margin-top: 60px;
} */
.footer-form .fa.fa-envelope-o {
  display: none;
}
/* awards_section */
section.awards_section {
  padding-top: 50px;
}
section.awards_section .P_gry_normal {
  max-width: 1042px;
  margin: auto;
  text-align: center;
}
section.awards_section .firstP {
  margin: 30px 0;
}
/* item_detail */
#awardsItem {
  padding-top: 130px;
}
.set_height_match {
  max-height: 60px;
  max-width: 60px;
  height: 100%;
  width: 100%;
}
.inner-card .img-box img {
  max-height: 100px;
  max-width: 100px;
  width: 100%;
  height: 100%;
  margin-left: 20px;
}
.item_detail h1 {
  font-weight: 700;
  font-size: 40px;
  color: #1f1e25;
}
.item_detail h5 {
  font-weight: 700;
  font-size: 40px;
  color: #1f1e25;
}
.brand_detail {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}
.brand_detail p:first-child {
  font-weight: 700;
  color: rgba(31, 30, 37, 0.5);
  width: 50%;
}
.brand_detail p:last-child {
  font-weight: 400;
  color: rgba(31, 30, 37, 0.5);
  width: 50%;
  text-align: end;
}
.product_descrpition {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
/* .post_btn {
  margin: 31px 0;
} */
.item_img {
  padding: 10px 10px;
  border: 1px solid #e1e1e1;
  margin-bottom: 30px;
  text-align: center;
}
.item_img img {
  width: 100%;
  height: auto;
  max-width: 300px;
}
.product_img_list {
  border: 1px solid #e1e1e1;
  padding: 20px;
  /* width: 170px; */
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide.swiper-slide-visible.swiper-slide-thumb-active .product_img_list {
  border: 1px solid #ff6a0d;
}
.awards_container.academy_container {
  gap: 35px 0;
  flex-wrap: wrap;
  margin-bottom: 50px;
  justify-content: space-between;
}
.academy_box {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-width: 30%;
}
.academy_box.earnpoints_box {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 16px;
}
.academy_box.earnpoints_box .post_btn {
  margin: 0 auto;
}
.academy_box img {
  border-radius: 15px;
  padding: 0;
  width: 100%;
  height: 100%;
}
.academy_box.earnpoints_box img {
  border-radius: 0px;
  padding: 0;
  width: 100%;
  height: 100%;
}
.points_box {
  background: #ff6a0d;
  border: 6px solid #ffffff;
  border-radius: 30px;
  max-width: 183px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 7px;
  position: relative;
  top: -36px;
  margin-bottom: -36px;
}
.academy_box .points_box p {
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
}
.academy_box h4 {
  text-align: center;
  font-weight: 700;
  font-size: 25px;
  color: #1f1e25;
  margin-bottom: 0;
}
.academy_box p {
  font-size: 16px;
  text-align: center;
  color: rgba(31, 30, 37, 0.5);
}
.academy_box h5 {
  font-weight: 700;
  font-size: 18px;
  color: #1f1e25;
  margin-bottom: 0;
}
.academy_box a {
  text-decoration: underline;
  font-weight: 700;
  font-size: 16px;
  color: #ff6a0d;
}
/* academy_container */
#academy_container .container {
  background-color: #fff;
  position: relative;
  top: -200px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 10px 0;
  margin-bottom: -200px;
}
#academy_container .P_gry_normal {
  margin-bottom: 16px;
}
#academy_container h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  color: #000000;
}
.width_set {
  max-width: 960px;
  margin: auto;
}
/* Top_home */
.Top_home {
  background: url(../public/images/home_top.jpg) no-repeat;
  background-position: center;
  background-size: cover;
}
.banner-text-box h6 {
  font-weight: 700;
  font-size: 60px;
  color: #ffffff;
}
.home-content {
  margin-bottom: 40px;
}
.video {
  width: 60%;
  height: 76%;
  max-width: 100%;
  position: absolute;
  left: 20.3%;
  top: 9.5%;
}
.main-video,
.video_video {
  width: 100%;
  height: 100%;
}
/* / add style on -07-11-2022 / */
section.contact_us {
  background: linear-gradient(90deg, #1f1e25 0%, #1f1e25 100%);
  padding: 50px 10px 100px;
}
.contact_heading h1 {
  font-family: "Space Grotesk";
  font-weight: 700;
  font-size: 200px;
  line-height: 255px;
  color: rgba(255, 255, 255, 0.03);
  max-width: fit-content;
  margin: auto;
}
.contact_heading h2 {
  position: absolute;
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #ff6a0d;
  max-width: fit-content;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.footer_m {
  margin: 0;
}
.contact_us_content h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  color: #ffffff;
}
.contact_us_content p {
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
}
.contact_box_img {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.05);
}
.contact_box_detail h6 {
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 0px;
}
.contact_us_form .input {
  width: 100%;
  min-height: 60px;
  padding: 11px 14px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: #ffffff;
  border-radius: 0;
}
.contact_us_form .input.submit_input {
  border: 1px solid #ff6a0d;
  background: #ff6a0d;
  color: #fff;
  font-weight: 700;
}
.contact_us_form .input,
.contact_us_form .input:hover,
.contact_us_form .input:focus {
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: transparent;
  color: #ffffff;
  padding: 11px 14px;
  min-height: 60px;
}
.contact_us_form .input.submit_input,
.contact_us_form .input.submit_input:hover,
.contact_us_form .input.submit_input:focus {
  border: 1px solid #ff6a0d;
  background: #ff6a0d;
  color: #fff;
  font-weight: 700;
}

/* / faq  / */
section.faq {
  padding: 50px 10px 50px;
}
.faq-section {
  margin: 50px 0;
}
.faq-items {
  width: 270px;
}
.faq-items .nav-link {
  width: 100%;
  min-height: 60px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 30px;
  position: relative;
  border-bottom: 1px solid #e1e1e1;
  font-size: 16px;
  line-height: 20px;
  color: #1f1e25;
  border-radius: 0;
}
.faq-items .nav-link:hover {
  font-weight: 400 !important;
  transform: skew(0);
}
.faq-items .nav-link::after,
.faq-items .nav-link::before {
  display: none;
}
.faq-items .nav-link.active::before {
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 6px;
  background-color: #f37727;
  transform: skew(0);
}
.faq-items .nav-link.active {
  background: linear-gradient(
    90deg,
    rgba(254, 203, 170, 0.38) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #f37727;
}
.faq-content {
  width: calc(100% - 300px);
}
.faq_accordios .accordion-item:not(:last-child) {
  margin-bottom: 16px;
}
.faq_accordios .accordion-item {
  background: #f7f7f7;
  border: 0;
}
.faq_accordios .accordion-item .accordion-button {
  background: #f7f7f7;
  padding: 20px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1f1e25;
}
.faq_accordios .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.faq_accordios .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}
.faq_accordios .accordion-body {
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.5);
}
.faq_accordios .accordion-body p {
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
}
/* / about / */
.about p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);
}
/* responsive */

/*laptop mode 1500 **/
@media (min-width: 1300px) and (max-width: 1500px) {
  .container {
    width: 1140px;
  }

  .tf h6 {
    font-size: var(--30px);
    font-weight: var(--bold);
    margin: 0px;
    padding: 0px;
  }

  .tf h3 {
    font-size: var(--25px);
    font-weight: var(--regular);
    padding-bottom: 30px;
    margin: 0px;
  }

  .tf p {
    margin: 0px;
    padding-top: 50px;
    font-size: var(--14px);
    color: var(--gray);
    font-weight: var(--regular);
  }

  .new-btn {
    color: var(--white);
    border: none;
    padding: 10px 25px;
    font-size: var(--14px);
    font-weight: var(--bold);
    transform: skewX(-30deg);
    transition: all 0.3s ease-out 0s;
    background-image: linear-gradient(to right, #ff900d, #ff6a0d);
  }

  .btn-black {
    background: var(--black);
    color: var(--white);
    border: none;
    padding: 10px 25px;
    font-size: var(--14px);
    transform: skewX(-30deg);
    transition: all 0.3s ease-out 0s;
  }

  .btn-icon {
    width: 20px;
    height: 20px;
    padding-right: 5px;
  }

  .navbar-nav .show .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--orange);
    font-size: var(--14px);
    font-weight: var(--bold);
    transform: skewX(0deg);
  }

  .nav-link.active::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link.active::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link {
    font-size: var(--14px);
    font-weight: var(--regular);
    color: var(--gray);
  }

  footer .text-1 {
    padding-left: 80px;
  }

  footer h1 {
    font-size: var(--14px);
    font-weight: var(--bold);
    color: var(--white);
    padding-bottom: 35px;
  }

  footer ul li a {
    text-decoration: none;
    font-size: var(--14px);
    line-height: 20px;
    color: var(--gray);
    font-weight: 400;
    padding: 0px;
  }

  footer .copy-right p {
    padding: 25px 0px;
    color: var(--gray);
    font-size: var(--14px);
    font-weight: var(--regular);
    text-align: center;
  }

  footer .form-control {
    display: block;
    width: 100%;
    padding: 10px 15px;
    font-size: var(--14px);
    color: var(--gray);
    background-color: var(--dark-black);
    background-clip: padding-box;
    border: 1px solid var(--border);
    margin-right: 0px !important;
    border-radius: 0px;
  }
}

/*landscape mode 1024 **/
@media (min-width: 992px) and (max-width: 1299px) {
  .container {
    padding: 0 15px;
  }

  .tf h6 {
    font-size: var(--40px);
    font-weight: var(--bold);
    margin: 0px;
    padding: 0px;
  }

  .tf h3 {
    font-size: var(--25px);
    font-weight: var(--regular);
    padding-bottom: 10px;
    margin: 0px;
  }

  .tf p {
    margin: 0px;
    padding-top: 20px;
    font-size: var(--14px);
    color: var(--gray);
    font-weight: var(--regular);
  }

  .new-btn {
    color: var(--white);
    border: none;
    padding: 10px 25px;
    font-size: var(--14px);
    font-weight: var(--bold);
    transform: skewX(-30deg);
    transition: all 0.3s ease-out 0s;
    background-image: linear-gradient(to right, #ff900d, #ff6a0d);
  }

  .btn-black {
    font-size: var(--14px);
    margin-left: 9px;
  }

  .btn-icon {
    width: 25px;
    height: 25px;
  }

  .navbar-nav .show .nav-link,
  .navbar-nav .nav-link.active {
    font-size: var(--14px);
  }

  .nav-link.active::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link.active::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link {
    font-size: var(--14px);
    font-weight: var(--regular);
    color: var(--gray);
  }
  .nav-item {
    padding: 0px 10px;
  }
  footer .text-1 {
    padding-left: 50px;
  }

  footer h1 {
    font-size: var(--14px);
    font-weight: var(--bold);
    color: var(--white);
    padding-bottom: 35px;
  }

  footer ul li a {
    text-decoration: none;
    font-size: var(--14px);
    line-height: 20px;
    color: var(--gray);
    font-weight: 400;
    padding: 0px;
  }

  footer .copy-right p {
    padding: 25px 0px;
    color: var(--gray);
    font-size: var(--14px);
    font-weight: var(--regular);
    text-align: center;
  }

  footer .form-control {
    display: block;
    width: 100%;
    padding: 10px 15px;
    font-size: var(--14px);
    color: var(--gray);
    background-color: var(--dark-black);
    background-clip: padding-box;
    border: 1px solid var(--border);
    margin-right: 0px !important;
    border-radius: 0px;
  }

  .Multiple-Sports-card .card {
    border: 1px solid var(--border);
    padding: 12px 30px;
    border-radius: 0px;
    transform: skewX(-16deg);
  }

  .points-img-box {
    width: 150px;
    height: auto;
  }
}

/*ipad*/

/*protreat mode 768**/
@media (min-width: 767px) and (max-width: 991px) {
  .container {
    width: 100%;
  }
  .banner {
    height: 70vh;
  }
  .tf .redeem-your-points .row.g-0 {
    position: absolute;
    bottom: -181px;
  }
  header button .fa.fa-bars {
    color: var(--orange);
  }

  .btn-icon {
    width: 20px;
    height: 20px;
    padding-right: 5px;
  }

  .navbar-toggler {
    border: 1px solid var(--orange);
    border-radius: 0px;
    padding: 8px 16px;
    transform: skewX(-30deg);
  }

  .fa.fa-bars {
    transform: skewX(30deg);
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }

  .nav-link.active {
    width: min-content;
  }

  .nav-link:hover {
    width: min-content;
  }

  .nav-item {
    padding: 5px 16px;
  }

  .tf h6 {
    font-size: var(--30px);
    font-weight: var(--bold);
    margin: 0px;
    padding: 0px;
  }

  .tf h3 {
    font-size: var(--25px);
    font-weight: var(--regular);
    padding-bottom: 20px;
    margin: 0px;
  }

  .tf p {
    margin: 0px;
    padding-top: 30px;
    font-size: var(--14px);
    color: var(--gray);
    font-weight: var(--regular);
  }

  .new-btn {
    color: var(--white);
    border: none;
    padding: 8px 15px;
    font-size: var(--14px);
    font-weight: var(--bold);
    transform: skewX(-30deg);
    transition: all 0.3s ease-out 0s;
    background-image: linear-gradient(to right, #ff900d, #ff6a0d);
  }

  .btn-black {
    font-size: var(--14px);
    margin-left: 25px;
  }

  header .d-flex {
    padding: 30px 30px;
  }

  .btn-icon {
    width: 20px;
    height: 20px;
    padding-right: 5px;
  }

  .navbar-nav .show .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--orange);
    font-size: var(--14px);
    font-weight: var(--bold);
    transform: skewX(0deg);
  }

  .nav-link.active::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link.active::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link {
    font-size: var(--14px);
    font-weight: var(--regular);
    color: var(--gray);
  }

  footer .text-1 {
    padding-left: 15px;
  }

  footer {
    background: var(--dark-black);
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin-top: 50px;
    color: var(--white);
    padding: 0px;
    padding-top: 0px;
    width: 100%;
    padding-top: 50px;
  }

  footer h1 {
    font-size: var(--14px);
    font-weight: var(--bold);
    color: var(--white);
    padding-bottom: 20px;
  }

  .text-1 ul li {
    padding-bottom: 10px;
  }

  footer ul li a {
    text-decoration: none;
    font-size: var(--14px);
    line-height: 20px;
    color: var(--gray);
    font-weight: 400;
    padding: 0px;
  }

  footer .copy-right p {
    padding: 25px 0px;
    color: var(--gray);
    font-size: var(--14px);
    font-weight: var(--regular);
    text-align: center;
  }

  footer .form-control {
    display: block;
    width: 100%;
    padding: 10px 15px;
    font-size: var(--14px);
    color: #fff;
    background-color: var(--dark-black);
    background-clip: padding-box;
    border: 1px solid var(--border);
    margin-right: 0px !important;
    border-radius: 0px;
  }
  .inner-card {
    transform: skewX(5deg);
  }
  .Multiple-Sports-card .card {
    border: 1px solid var(--border);
    padding: 20px 15px;
    border-radius: 0px;
    transform: skewX(-5deg);
  }

  .d-flex {
    padding: 5px;
  }

  #navbarNavDropdown ul {
    padding: 5px 0px;
  }

  .banner-text-box {
    text-align: center;
    font-size: var(--60px);
    font-weight: var(--bold);
    margin: 0px;
    padding-top: 80px;
  }

  .footer-form a {
    background: var(--white);
    padding: 10px;
    display: flex;
    cursor: pointer;
  }

  .banner {
    background: url(../public/images/banner.jpg);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    height: 650px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin: 0px;
    color: #ffffff;
    width: 100%;
  }

  .points-img-box {
    width: 100px;
    height: auto;
  }
  .tf h4 {
    padding-top: 7px;
  }
  .redeem-your-points .card {
    max-height: 215px;
  }
  /* .Multiple-Sports-card .card:hover h4 {
    font-size: var(--20px);
    font-weight: var(--bold);
    color: var(--white);
    margin: 0px;
  } */

  .Multiple-Sports-card .inner-card p {
    margin: 0px;
    padding-top: 15px;
    font-size: var(--14px);
    color: var(--gray);
    font-weight: var(--regular);
  }

  .card_img {
    width: 80px;
    height: 80px;
  }

  .tf h2 {
    font-size: var(--14px);
    font-weight: bold;
    color: var(--black);
  }

  .bg-img {
    background: url(../public/images/section-bg.png);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    height: 1250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin: 0px;
    color: #ffffff;
    width: 100%;
    padding: 0px;
  }

  .tf {
    padding-top: 50px;
  }

  .Multiple-Sports {
    padding-bottom: 50px;
  }

  .tf.bg-img {
    padding: 0px;
    margin-bottom: 56px;
  }
  .section h1 {
    font-size: 45px;
  }
  .section_head {
    margin: 20px 0;
  }
  .tf .redeem-your-points .row.g-0 {
    position: absolute;
    bottom: -184px;
  }
}

/*mobile view 767 **/

/* md  */
@media (min-width: 1500px) {
  .video {
    width: 57%;
    height: 76%;
    max-width: 100%;
    position: absolute;
    left: 20%;
    top: 9.5%;
  }
}
@media (max-width: 1500px) {
  .login_content .input {
    padding: 10px 44px 10px 24px;
    min-height: 47px;
  }
  .form-control.floating:focus ~ label,
  .form-control.floating:valid ~ label {
    transform: translateY(-24px) scale(0.8);
  }
}
@media (max-width: 1350px) and (min-width: 992px) {
  ul#tabs li {
    padding: 16px 17px;
  }
}
@media (max-width: 1300px) {
  .row.g-0 {
    padding: 0 60px;
  }
  .banner {
    height: 100vh;
  }
  .tf h4 {
    padding-top: 15px;
  }
}
@media (max-width: 1200px) {
  .product_img_list {
    width: 150px;
    height: 150px;
  }
  #academy_container .container {
    background-color: #fff;
    position: relative;
    top: 0px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 10px;
    margin-bottom: 0px;
  }
  #academy_container .h1_60 {
    font-size: 40px;
  }
  #academy_container h2 {
    font-size: 30px;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .video {
    height: 76%;
    left: 20%;
    max-width: 100%;
    position: absolute;
    top: 9.5%;
    width: 57.5%;
  }
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  header img {
    width: 150px;
  }
  .btn-black {
    padding: 5px 10px;
  }
  .nav-link {
    font-size: 12px;
  }
}
@media (max-width: 991px) {
  .login-container {
    max-height: 100%;
  }
  .Product_box {
    width: 33.33%;
  }
  section.section {
    padding: 30px 0;
  }
  .banner {
    height: 70vh;
  }
  .Login_body {
    height: 100%;
    padding-top: 30px;
  }
  .input_icon {
    bottom: 14px;
  }
  .form-control.floating:focus ~ label {
    transform: translateY(-26px) scale(0.8);
  }
  .form-control.floating:valid ~ label {
    transform: translateY(-26px) scale(0.8);
  }
  ul#tabs li {
    padding: 10px 24px;
    min-width: auto;
  }
  .tabs_container ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 10px;
  }
  #Profile {
    padding: 30px 0;
    background-color: #1f1e25;
  }
  .profile_dp {
    width: 100px;
    height: 100px;
    max-width: 100%;
    object-fit: cover;
  }
  .profile_section_left {
    column-gap: 10px;
  }
  .profile_detail {
    row-gap: 0px;
  }
  p.selected_file::before {
    left: 0px;
  }
  p.selected_file {
    text-align: center;
  }
  #Tabs_section {
    padding: 20px 0;
  }
  .bats_nav ul {
    column-gap: 18px;
    padding: 0 0px;
  }
  .bats_nav ul {
    margin: 18px auto;
  }
  .friend_list.post_container {
    padding: 14px 25px 14px 3px !important;
  }
  .theWall {
    margin-top: 20px;
  }
  .form_container .input {
    min-height: 39px !important;
  }
  .input {
    min-height: 40px;
  }
  .input,
  .input:hover,
  .input:focus {
    padding: 11px 14px;
  }
  .labels {
    top: 7px;
  }
  .gameBegin h1.section_head {
    margin: 14px 0 0;
    font-size: 27px;
  }
  .gameBegin {
    row-gap: 12px;
  }
  .profile_box {
    display: none !important;
  }
  .profile_box_responsive {
    position: absolute;
    top: 9px;
    right: 94px;
    display: flex !important;
  }
  .profile_box_responsive #profileOpen.active {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(30px);
    visibility: visible;
  }
  section.contact_us {
    padding: 30px 10px 60px;
  }
  .about-content {
    padding: 0 20px;
  }
  .faq-section .d-flex:first-child {
    flex-wrap: wrap;
    gap: 30px;
  }
  .faq-items {
    flex-direction: row !important;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }
  .faq-items .nav-link.active {
    width: 16.66%;
    background: linear-gradient(
      0deg,
      rgba(254, 203, 170, 0.38) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .faq-items .nav-link.active::before {
    top: 100%;
    left: 0%;
    height: 6px;
    width: 100%;
  }
  .faq-items .nav-link,
  .faq-items .nav-link:hover {
    width: 16.66%;
  }
  .faq-content {
    width: 100%;
  }
  .contact_heading h1 {
    font-size: 100px;
    line-height: 87px;
  }
  .contact_heading h2 {
    font-size: 24px;
  }
  .contact_us_content h1 {
    font-size: 32px;
  }
  .contact_us_content p.mt-3.mb-5 {
    margin: 12px 0 !important;
  }
  .faq-items .nav-link,
  .faq-items .nav-link.active {
    min-height: 50px;
    padding-left: 0 !important;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 800px) {
  .bats_nav ul {
    column-gap: 10px;
    padding: 0 0px;
  }
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }
  .Product_box {
    width: 50%;
  }
  .content-container {
    margin-bottom: 30px;
    padding: 0 15px;
  }
  .content-container ul:first-child li {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .content-container ul:first-child li ul:first-child {
    margin-top: 3px;
  }
  .content-container ul:first-child li:first-child ul li {
    font-size: 14px;
    line-height: 20px;
  }
  .content-container ul:first-child li ul li ul:first-child li {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 4px;
  }
  header button .fa.fa-bars {
    color: var(--orange);
  }

  .btn-icon {
    width: 20px;
    height: 20px;
    padding-right: 5px;
  }

  .navbar-toggler {
    border: 1px solid var(--orange);
    border-radius: 0px;
    padding: 6px 10px;
    transform: skewX(-30deg);
    font-size: var(--14px);
  }

  .fa.fa-bars {
    transform: skewX(30deg);
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }

  .nav-link.active {
    width: min-content;
  }

  .nav-link:hover {
    width: max-content;
  }

  .nav-item {
    padding: 0px 16px;
  }

  .tf h6 {
    font-size: var(--25px);
    font-weight: var(--bold);
    margin: 0px;
    padding: 0px;
  }

  .tf h3 {
    font-size: var(--20px);
    font-weight: var(--regular);
    padding-bottom: 10px;
    margin: 0px;
  }

  .tf p {
    margin: 0px;
    padding-top: 20px;
    font-size: var(--14px);
    color: var(--gray);
    font-weight: var(--regular);
  }

  .new-btn {
    color: var(--white);
    border: none;
    padding: 8px 15px;
    font-size: var(--14px);
    font-weight: var(--bold);
    transform: skewX(-30deg);
    transition: all 0.3s ease-out 0s;
    background-image: linear-gradient(to right, #ff900d, #ff6a0d);
    top: -25px;
    position: relative;
  }

  .btn-black {
    background: var(--black);
    color: var(--white);
    border: none;
    padding: 8px 15px;
    font-size: var(--14px);
    transform: skewX(-30deg);
    transition: all 0.3s ease-out 0s;
    margin-left: 17px;
  }

  header .d-flex {
    padding: 15px 30px;
  }

  .btn-icon {
    width: 20px;
    height: 20px;
    padding-right: 5px;
  }

  .navbar-nav .show .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--orange);
    font-size: var(--14px);
    font-weight: var(--bold);
    transform: skewX(0deg);
  }

  .nav-link.active::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link.active::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link {
    font-size: var(--14px);
    font-weight: var(--regular);
    color: var(--gray);
  }

  footer .text-1 {
    padding-top: 30px;
    padding-left: 0px;
  }

  footer {
    background: var(--dark-black);
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin-top: 50px;
    color: var(--white);
    padding: 0px;
    padding-top: 0px;
    width: 100%;
    padding-top: 50px;
    text-align: center;
  }

  footer h1 {
    font-size: var(--14px);
    font-weight: var(--bold);
    color: var(--white);
    padding-bottom: 10px;
  }

  .text-1 ul li {
    padding-bottom: 5px;
  }

  footer ul li a {
    text-decoration: none;
    font-size: var(--14px);
    line-height: 20px;
    color: var(--gray);
    font-weight: 400;
    padding: 0px;
  }

  footer .copy-right p {
    padding: 25px 0px;
    color: var(--gray);
    font-size: var(--14px);
    font-weight: var(--regular);
    text-align: center;
  }

  footer .form-control {
    display: block;
    width: 100%;
    padding: 10px 15px;
    font-size: var(--14px);
    color: var(--gray);
    background-color: var(--dark-black);
    background-clip: padding-box;
    border: 1px solid var(--border);
    margin-right: 0px !important;
    border-radius: 0px;
  }

  .Multiple-Sports-card .card {
    border: 1px solid var(--border);
    padding: 20px 40px;
    border-radius: 0px;
    transform: skewX(-16deg);
    margin-bottom: 20px;
  }

  .d-flex {
    padding: 5px;
  }

  #navbarNavDropdown ul {
    padding: 5px 0px;
  }

  .banner-text-box {
    text-align: center;
    font-size: var(--60px);
    font-weight: var(--bold);
    margin: 0px;
    padding-top: 70px;
  }
  .home-content {
    margin-bottom: 20px;
  }

  .footer-form a {
    background: var(--white);
    padding: 10px;
    display: flex;
  }

  .banner {
    background: url(../public/images/banner.jpg);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin: 0px;
    color: #ffffff;
    width: 100%;
  }

  header {
    padding: 0px 0px;
    background: var(--dark-black);
    position: fixed;
    z-index: 66;
    width: 100%;
    border-bottom: 1px solid var(--black);
  }

  .points-img-box {
    width: auto;
    height: auto;
  }

  .tf h4 {
    font-size: var(--20px);
    font-weight: var(--bold);
    color: var(--black);
    margin: 0px;
    padding-top: 20px;
  }

  .Multiple-Sports-card .card:hover h4 {
    font-size: var(--20px);
    font-weight: var(--bold);
    color: var(--white);
    margin: 0px;
  }

  .Multiple-Sports-card .inner-card p {
    margin: 0px;
    padding-top: 15px;
    font-size: var(--14px);
    color: var(--gray);
    font-weight: var(--regular);
  }

  .card_img {
    width: 80px;
    height: 80px;
  }

  .tf h2 {
    font-size: var(--14px);
    font-weight: bold;
    color: var(--black);
  }

  .bg-img {
    background: url(../public/images/section-bg.png);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    height: 2900px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin: 0px;
    color: #ffffff;
    width: 100%;
    padding: 0px;
  }

  .tf.bg-white-img {
    padding-top: 0px;
  }

  .tf {
    padding-top: 50px;
  }

  .Multiple-Sports {
    padding-bottom: 50px;
    padding-top: 30px;
  }

  .tf .bg-img {
    padding: 0px;
  }

  header img {
    width: 100px;
  }

  .navbar-nav.mx-auto.mr-auto.d-lg-flex {
    padding-top: 10px;
  }

  .tf.bg-img {
    padding-top: 0px;
    height: auto;
  }
  .tf .redeem-your-points {
    padding-bottom: 10px;
  }

  .money-img-box {
    padding-bottom: 20px;
  }

  .casino-img-box {
    padding-top: 30px;
  }

  .padding-b-50px {
    padding-bottom: 20px;
  }

  .redeem-your-points .card {
    border: 1px solid var(--border);
    border-radius: 0px;
    transform: skewX(-16deg);
    padding: 50px 20px;
    display: flex;
    margin-bottom: 20px;
  }

  .tf h2 {
    font-size: var(--20px);
    font-weight: bold;
    color: var(--black);
  }

  .tf .redeem-your-points .row.g-0 {
    position: relative;
    bottom: auto;
    display: flex;
  }
  .tf .redeem-your-points .row.g-0 .col-md-3 {
    width: 50%;
  }
  .redeem-your-points .card {
    max-height: 223px;
    transform: skewX(-5deg);
  }
  .redeem-your-points .inner-card {
    transform: skewX(5deg);
    margin: auto;
  }
  .points-img-box img {
    width: 100px;
    height: 64px;
    object-fit: cover;
  }
  .section_heading .heading_line {
    height: 2px;
  }
  .section h1 {
    font-size: 35px;
  }
  .section_head {
    margin: 10px 0;
  }
}
@media (max-width: 767px) {
  p.balence_p {
    margin-bottom: 3px;
    margin-top: 7px;
  }
  .tabs_container ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 5px;
  }
  ul#tabs li:last-child {
    border-left: 1px solid #e1e1e1 !important;
  }
  ul#tabs li:last-child {
    border-left: 1px solid #e1e1e1;
  }
  .user_image img {
    width: 42px;
    height: 42px;
  }
  .post_detail {
    margin-top: 10px;
  }
  .post_detail .row {
    display: flex;
    padding: 0 8px;
  }
  .post_detail .col-md-1 {
    width: 10%;
    margin: 0;
    padding: 0;
  }
  .post_detail .col-md-8 {
    width: 50%;
    padding: 0;
  }
  .post_detail .col-md-3 {
    width: 40%;
  }
  .SelectImg {
    max-height: 50px;
    min-height: auto;
  }
  .SelectImg .input {
    min-height: 50px;
  }
  p.selected_file::before {
    top: -14px;
    height: 50px;
  }
  .input,
  .input:hover,
  .input:focus {
    border: 1px solid #cad2d9;
    background: transparent;
    color: #1f1e25;
    /* padding: 3px; */
    min-height: 47px;
  }
  .form_container .input {
    min-height: 47px;
  }
  .SelectImg label {
    position: absolute;
    left: 14px;
  }
  .post_container {
    padding: 20px 0;
  }
  .post_container .row {
    display: flex;
    padding: 0 8px;
  }
  .post_container .col-md-1 {
    width: 10%;
    margin: 0;
    padding: 0;
  }
  .post_container .col-md-10 {
    width: 80%;
  }
  .post_container .col-md-1 {
    width: 10%;
    margin: 0;
    padding: 0;
  }
  .add_post {
    margin-bottom: 20px;
  }
  .wallet_profile {
    padding: 10px 20px;
    margin: 20px 0;
    background-size: cover;
  }
  .recent_transitions {
    margin: 20px 0;
  }
  /* .transition_table.table-responsive {
    min-width: 767px !important;
    overflow-x: scroll !important;
    white-space: nowrap;
  } */
  .table thead tr th,
  .table tbody tr td {
    min-width: 70px !important;
  }
  .table thead tr th:first-child,
  .table tbody tr td:first-child {
    min-width: 110px !important;
    padding-left: 0;
  }
  .upcoming_matches_table .table thead tr th,
  .upcoming_matches_table .table tbody tr td {
    min-width: 70px !important;
  }
  .table thead tr th:nth-child(2),
  .table tbody tr td:nth-child(2),
  .upcoming_matches_table .table thead tr th:nth-child(2),
  .upcoming_matches_table .table tbody tr td:nth-child(2) {
    min-width: 300px !important;
  }
  .balence_col {
    column-gap: 10px;
  }
  .balence_box h2 {
    font-size: 25px;
  }
  .selectFormat {
    min-width: 200px;
  }
  .upcoming_matches_list {
    padding: 10px 25px;
  }
  .tebs_content h1 {
    font-size: 25px;
  }
  .friend_list.post_container .row {
    display: flex;
    flex-wrap: nowrap;
  }
  .friend_list.post_container .row .col-md-1 {
    width: 8.33333333%;
    margin-right: 18px;
  }
  .friend_list.post_container .row .col-md-9 {
    width: 70%;
    padding: 0 10px;
  }
  .friend_list.post_container .row .col-md-2 {
    width: 16.66666667%;
  }
  ul#friends_tabs li a {
    font-size: 11px;
    line-height: 15px;
  }
  ul#friends_tabs li div {
    padding-bottom: 6px;
  }
  .card-header {
    display: block !important;
  }
  p.poster_name {
    font-size: 14px;
  }
  .testimonial_row {
    min-height: 610px;
  }
  .testimonial-column1 {
    padding: 0 55px;
  }
  .gamebegin_row {
    padding: 137px 10px 1px;
  }
  section.section {
    padding: 100px 0 0;
  }
  section.section {
    padding: 40px 0 0;
  }
  .banner {
    height: 50vh;
  }
  footer .row .col-md-3:nth-child(2) {
    width: 26%;
    text-align: start;
  }
  footer .row .col-md-3:nth-child(3) {
    width: 26%;
    text-align: start;
    display: flex;
    justify-content: center;
  }
  footer .row .col-md-3:nth-child(4) {
    width: 40%;
    text-align: start;
    display: flex;
    justify-content: end;
  }
  .product_img_list {
    width: 120px;
    height: 120px;
  }
  #awardsItem {
    padding-top: 77px;
  }
  .item_detail h1 {
    font-size: 25px;
  }
  .academy_box {
    max-width: 47%;
    row-gap: 5px;
  }
  section.awards_section {
    padding-top: 18px;
  }
  .h1_60 {
    font-size: 38px;
  }
  section.awards_section .firstP {
    margin: 16px 0;
  }
  .P_gry_normal {
    font-size: 14px;
  }
  .ad_section {
    margin: 30px 0;
  }
  .academy_box h5 {
    font-size: 15px;
  }
  #academy_container .h1_60 {
    font-size: 30px;
  }
  #academy_container h2 {
    font-size: 25px;
  }
  .profile_box_responsive {
    position: absolute;
    top: 6px;
    right: 70px;
  }
  .profile_box_responsive .btn-black {
    padding: 4px 11px;
  }
  .profile_box_responsive #profileOpen.active {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(3px);
    visibility: visible;
  }
  .upcoming_matches_table {
    padding: 10px 10px 0 7px;
  }
  .table > :not(caption) > * > * {
    padding: 8px 2px;
  }
  .table h5 {
    font-size: 14px;
    margin-bottom: 0;
  }
  .upcoming_matches_table .table thead tr th,
  .upcoming_matches_table .table tbody tr td {
    min-width: 50px !important;
  }
  .table thead tr th {
    font-size: 14px;
  }
  .table .btnFill.betbtn,
  .table .betClosed {
    width: 118px;
    height: 32px;
  }
  .table .btnfilltext,
  .table .post_btn,
  .table .betClosed {
    font-size: 14px;
  }
  .table tbody tr td {
    font-size: 14px;
  }
  .notification_box h5 {
    margin-bottom: 10px;
  }
  .Label {
    margin-bottom: 10px;
  }
  .checkboxs {
    padding-left: 0px;
  }
  .control--checkbox span {
    margin-left: 3px;
    font-size: 14px;
  }
  .control--checkbox {
    padding-left: 21px;
  }
  .control__indicator {
    top: 6px;
    left: 0;
    height: 15px;
    width: 15px;
  }
  .control .control__indicator::after {
    left: 4px;
    top: 2px;
    width: 6px;
    height: 9px;
  }
  .control--checkbox {
    margin-bottom: 7px;
  }
  .radio {
    display: flex;
    column-gap: 0;
    justify-content: space-between;
  }
  .submit_box .post_btn {
    margin: 0 0 0 15px;
    width: 83px;
    padding: 0;
    height: 42px;
  }
  footer {
    margin-top: 0;
    padding-top: 20px;
  }
  ul#tabs {
    display: flex;
    margin-top: 0px;
  }
  ul#tabs li {
    padding: 10px;
  }
  .bats_nav ul li div {
    padding-bottom: 5px;
    text-align: center;
  }
  .form_container form .row div {
    margin-bottom: 16px;
    padding: 0 17px;
  }
  .form_container .post_btn.smallbtn {
    width: 89px;
    margin: 0px 0 0 10px;
    padding: 0;
    height: 42px;
  }
  .post_btn {
    width: 150px;
    height: 43px;
    padding: 0;
  }
  .control--radio .control__indicator {
    left: 0px;
  }
  .post_btn.smallbtn {
    padding: 12px;
  }
  .btnfilltext {
    line-height: 20px;
  }
  .Withdraw_row {
    column-gap: 8px;
  }
  .faq-items .nav-link,
  .faq-items .nav-link.active {
    min-height: 54px;
    padding: 0 !important;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 653px) and (min-width: 460px) {
  ul#tabs li:nth-child(5) {
    border-left: 1px solid #e1e1e1;
  }
}
@media (max-width: 460px) {
  ul#tabs li:nth-child(4) {
    border-left: 1px solid #e1e1e1;
  }
}
@media (max-width: 499px) {
  P {
    font-size: 15px;
  }
  ul#friends_tabs li div {
    line-height: 15px;
  }
  P {
    font-size: 14px;
  }
  .profile_dp {
    width: 80px;
    height: 80px;
  }
  .profile_detail h1 {
    font-size: 25px;
  }
  .profile_detail p {
    font-size: 12px;
  }
  p.balence_p {
    margin-bottom: 0px;
    margin-top: 4px;
  }
  .post_detail .row {
    display: flex;
    padding: 0 8px;
    flex-wrap: wrap;
  }
  .post_detail .col-md-1 {
    width: 10%;
    margin: 0;
    padding: 0;
  }
  .post_detail .col-md-8 {
    width: 85%;
    padding: 0;
  }
  .post_detail .col-md-3 {
    width: 91%;
    text-align: right;
    margin-left: 12%;
  }
  .selected_images {
    gap: 10px;
  }
  .post_imagge {
    height: 100%;
    max-width: 100px;
    max-height: 100px;
    object-fit: contain;
  }
  .img_options img {
    width: 30px;
  }
  .post_container .col-md-10 {
    padding: 0 16px;
  }
  .friend_list.post_container .row .col-md-9 {
    width: 64%;
    padding: 0 10px;
    max-width: 244px;
  }
  p.date_time {
    margin-top: 0px;
  }
  ul#friends_tabs li div {
    padding-bottom: 4px;
  }
  ul#Sports_tabs {
    max-width: 344px;
    column-gap: 18px;
  }
  ul#Sports_tabs li div {
    row-gap: 6px;
  }
  .sports_img {
    width: 60px;
    height: 60px;
  }
  ul#Sports_tabs li div svg {
    width: 30px;
    height: 30px;
  }
  ul#Sports_tabs li.sports_active a {
    font-size: 14px;
  }
  .sports_active_show div a {
    font-size: 14px;
  }
  .modal-content {
    padding: 0px;
  }
  .match_detail {
    padding: 10px;
    margin-top: 9px;
    margin-bottom: 11px;
  }
  .team_logo {
    width: 80px;
    height: 80px;
  }
  .post_btn.fly_btn {
    height: 49px;
  }
  .testimonial_row {
    min-height: 590px;
  }
  .testimonial-column1 {
    padding: 0 15px;
  }
  .testimonial_arrows {
    position: initial;
    width: auto;
    height: auto;
  }
  .swiper-button-prev {
    left: 40px !important;
    right: auto !important;
  }
  .swiper-button-next {
    right: 40px !important;
    left: auto !important;
  }
  footer .row .col-md-3:nth-child(2) {
    width: 50%;
    text-align: start;
  }
  footer .row .col-md-3:nth-child(3) {
    width: 50%;
    text-align: start;
    display: flex;
    justify-content: center;
  }
  footer .row .col-md-3:nth-child(4) {
    width: 100%;
    text-align: start;
    display: flex;
    justify-content: end;
  }
  .banner {
    height: 36vh;
  }
  .tf {
    padding-top: 17px;
  }
  .tf h6 {
    font-size: 17px;
  }
  .Multiple-Sports-card .card {
    border: 1px solid var(--border);
    padding: 4px 15px;
    border-radius: 0px;
    transform: skewX(-16deg);
    margin-bottom: 8px;
  }
  .section_heading h2 {
    font-size: 22px;
  }
  .section h1 {
    font-size: 23px;
  }
  .section_head {
    margin: 5px 0;
  }
  footer .row .col-md-3:nth-child(4) {
    justify-content: start;
  }
  .gamebegin_row {
    padding: 74px 10px 1px;
  }
  .academy_box {
    max-width: 100%;
  }
  .balence_col .balence_icon {
    width: 50px;
    height: 50px;
  }
  .balence_box h2 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .balence_box p {
    font-size: 17px;
  }
  section.contact_us {
    padding: 20px 0px 30px;
  }
  .contact_heading h1 {
    font-size: 70px;
    line-height: 70px;
  }
  .contact_heading h2 {
    font-size: 20px;
  }
  .contact_us_content h1 {
    font-size: 24px;
    line-height: 25px;
  }
  .contact_us_content p.mt-3.mb-5 {
    margin: 8px 0 !important;
  }
  section.faq {
    padding: 15px 0px 0px;
  }
  .faq-section {
    margin: 15px 0;
  }
  .faq-items .nav-link,
  .faq-items .nav-link.active {
    min-height: 34px;
    padding: 0px !important;
    font-size: 8px;
    line-height: 10px;
    display: flex;
    justify-content: center;
  }
  .faq-section .d-flex {
    padding: 0px;
  }
  .faq-items .nav-link.active::before {
    height: 3px;
  }
  .faq_accordios .accordion-item .accordion-button {
    font-size: 13px;
    line-height: 20px;
    padding: 14px 10px;
  }
  .faq_accordios .accordion-body {
    font-size: 13px;
    line-height: 22px;
  }
  .accordion-button::after {
    width: 1rem;
    height: 1rem;
    background-size: 1rem;
  }
}
@media (max-width: 400px) {
  .swiper-button-next {
    right: 25px !important;
  }
  .Product_box {
    width: 100%;
  }
  .swiper-button-prev {
    left: 25px !important;
  }
  .row.dropdowns {
    padding: 10px;
  }
  .upcoming_matches_list {
    flex-wrap: wrap;
    padding: 10px;
  }
  .upcoming_matches_list .col-md-3.col-sm-6 {
    width: 100%;
  }
  .user_image img {
    width: 35px;
    height: 35px;
  }
}
