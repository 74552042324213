/*laptop mode 1500 **/
@media (min-width: 1300px) and (max-width: 1500px) {
  .container {
    width: 1140px;
  }

  .tf h6 {
    font-size: var(--30px);
    font-weight: var(--bold);
    margin: 0px;
    padding: 0px;
  }

  .tf h3 {
    font-size: var(--25px);
    font-weight: var(--regular);
    padding-bottom: 30px;
    margin: 0px;
  }

  .tf p {
    margin: 0px;
    padding-top: 50px;
    font-size: var(--14px);
    color: var(--gray);
    font-weight: var(--regular);
  }

  .new-btn {
    color: var(--white);
    border: none;
    padding: 10px 25px;
    font-size: var(--14px);
    font-weight: var(--bold);
    transform: skewX(-30deg);
    transition: all 0.3s ease-out 0s;
    background-image: linear-gradient(to right, #ff900d, #ff6a0d);
  }

  .btn-black {
    background: var(--black);
    color: var(--white);
    border: none;
    padding: 10px 25px;
    font-size: var(--14px);
    transform: skewX(-30deg);
    transition: all 0.3s ease-out 0s;
  }

  .btn-icon {
    width: 20px;
    height: 20px;
    padding-right: 5px;
  }

  .navbar-nav .show .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--orange);
    font-size: var(--14px);
    font-weight: var(--bold);
    transform: skewX(0deg);
  }

  .nav-link.active::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link.active::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link {
    font-size: var(--14px);
    font-weight: var(--regular);
    color: var(--gray);
  }

  footer .text-1 {
    padding-left: 80px;
  }

  footer h1 {
    font-size: var(--14px);
    font-weight: var(--bold);
    color: var(--white);
    padding-bottom: 35px;
  }

  footer ul li a {
    text-decoration: none;
    font-size: var(--14px);
    line-height: 20px;
    color: var(--gray);
    font-weight: 400;
    padding: 0px;
  }

  footer .copy-right p {
    padding: 25px 0px;
    color: var(--gray);
    font-size: var(--14px);
    font-weight: var(--regular);
    text-align: center;
  }

  footer .form-control {
    display: block;
    width: 100%;
    padding: 10px 15px;
    font-size: var(--14px);
    color: var(--gray);
    background-color: var(--dark-black);
    background-clip: padding-box;
    border: 1px solid var(--border);
    margin-right: 0px !important;
    border-radius: 0px;
  }
}

/*landscape mode 1024 **/
@media (min-width: 992px) and (max-width: 1299px) {
  .container {
    padding: 0 15px;
  }

  .tf h6 {
    font-size: var(--40px);
    font-weight: var(--bold);
    margin: 0px;
    padding: 0px;
  }

  .tf h3 {
    font-size: var(--25px);
    font-weight: var(--regular);
    padding-bottom: 10px;
    margin: 0px;
  }

  .tf p {
    margin: 0px;
    padding-top: 20px;
    font-size: var(--14px);
    color: var(--gray);
    font-weight: var(--regular);
  }

  .new-btn {
    color: var(--white);
    border: none;
    padding: 10px 25px;
    font-size: var(--14px);
    font-weight: var(--bold);
    transform: skewX(-30deg);
    transition: all 0.3s ease-out 0s;
    background-image: linear-gradient(to right, #ff900d, #ff6a0d);
  }

  .btn-black {
    font-size: var(--14px);
    margin-left: 25px;
  }

  .btn-icon {
    width: 20px;
    height: 20px;
    padding-right: 5px;
  }

  .nav-link.active::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link.active::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link {
    font-size: var(--14px);
    font-weight: var(--regular);
    color: var(--gray);
  }

  footer .text-1 {
    padding-left: 50px;
  }

  footer h1 {
    font-size: var(--14px);
    font-weight: var(--bold);
    color: var(--white);
    padding-bottom: 35px;
  }

  footer ul li a {
    text-decoration: none;
    font-size: var(--14px);
    line-height: 20px;
    color: var(--gray);
    font-weight: 400;
    padding: 0px;
  }

  footer .copy-right p {
    padding: 25px 0px;
    color: var(--gray);
    font-size: var(--14px);
    font-weight: var(--regular);
    text-align: center;
  }

  footer .form-control {
    display: block;
    width: 100%;
    padding: 10px 15px;
    font-size: var(--14px);
    color: var(--gray);
    background-color: var(--dark-black);
    background-clip: padding-box;
    border: 1px solid var(--border);
    margin-right: 0px !important;
    border-radius: 0px;
  }

  .Multiple-Sports-card .card {
    border: 1px solid var(--border);
    padding: 12px 30px;
    border-radius: 0px;
    transform: skewX(-16deg);
    height: 100%;
  }

  .points-img-box {
    width: 150px;
    height: auto;
  }
}

/*ipad*/

/*protreat mode 768**/
@media (min-width: 767px) and (max-width: 991px) {
  .container {
    width: 100%;
  }
  .banner {
    height: 70vh;
  }
  .tf .redeem-your-points .row.g-0 {
    position: absolute;
    bottom: -181px;
  }
  header button .fa.fa-bars {
    color: var(--orange);
  }

  .btn-icon {
    width: 20px;
    height: 20px;
    padding-right: 5px;
  }

  .navbar-toggler {
    border: 1px solid var(--orange);
    border-radius: 0px;
    padding: 8px 16px;
    transform: skewX(-30deg);
  }

  .fa.fa-bars {
    transform: skewX(30deg);
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }

  .nav-link.active {
    width: min-content;
  }

  .nav-link:hover {
    width: min-content;
  }

  .nav-item {
    padding: 5px 16px;
  }

  .tf h6 {
    font-size: var(--30px);
    font-weight: var(--bold);
    margin: 0px;
    padding: 0px;
  }

  .tf h3 {
    font-size: var(--25px);
    font-weight: var(--regular);
    padding-bottom: 20px;
    margin: 0px;
  }

  .tf p {
    margin: 0px;
    padding-top: 30px;
    font-size: var(--14px);
    color: var(--gray);
    font-weight: var(--regular);
  }

  .new-btn {
    color: var(--white);
    border: none;
    padding: 8px 15px;
    font-size: var(--14px);
    font-weight: var(--bold);
    transform: skewX(-30deg);
    transition: all 0.3s ease-out 0s;
    background-image: linear-gradient(to right, #ff900d, #ff6a0d);
  }

  .btn-black {
    font-size: var(--14px);
    margin-left: 25px;
  }

  header .d-flex {
    padding: 30px 30px;
  }

  .btn-icon {
    width: 20px;
    height: 20px;
    padding-right: 5px;
  }

  .navbar-nav .show .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--orange);
    font-size: var(--14px);
    font-weight: var(--bold);
    transform: skewX(0deg);
  }

  .nav-link.active::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link.active::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link {
    font-size: var(--14px);
    font-weight: var(--regular);
    color: var(--gray);
  }

  footer .text-1 {
    padding-left: 15px;
  }

  footer {
    background: var(--dark-black);
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin-top: 50px;
    color: var(--white);
    padding: 0px;
    padding-top: 0px;
    width: 100%;
    padding-top: 50px;
  }

  footer h1 {
    font-size: var(--14px);
    font-weight: var(--bold);
    color: var(--white);
    padding-bottom: 20px;
  }

  .text-1 ul li {
    padding-bottom: 10px;
  }

  footer ul li a {
    text-decoration: none;
    font-size: var(--14px);
    line-height: 20px;
    color: var(--gray);
    font-weight: 400;
    padding: 0px;
  }

  footer .copy-right p {
    padding: 25px 0px;
    color: var(--gray);
    font-size: var(--14px);
    font-weight: var(--regular);
    text-align: center;
  }

  footer .form-control {
    display: block;
    width: 100%;
    padding: 10px 15px;
    font-size: var(--14px);
    color: #fff;
    background-color: var(--dark-black);
    background-clip: padding-box;
    border: 1px solid var(--border);
    margin-right: 0px !important;
    border-radius: 0px;
  }
  .inner-card {
    transform: skewX(5deg);
  }
  .Multiple-Sports-card .card {
    border: 1px solid var(--border);
    padding: 20px 15px;
    border-radius: 0px;
    transform: skewX(-5deg);
  }

  .d-flex {
    padding: 5px;
  }

  #navbarNavDropdown ul {
    padding: 5px 0px;
  }

  .banner-text-box {
    text-align: center;
    font-size: var(--60px);
    font-weight: var(--bold);
    margin: 0px;
    padding-top: 80px;
  }

  .footer-form a {
    background: var(--white);
    padding: 10px;
    display: flex;
  }

  .banner {
    background: url(../../../public/images/banner.jpg);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    height: 650px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin: 0px;
    color: #ffffff;
    width: 100%;
  }

  .points-img-box {
    width: 100px;
    height: auto;
  }
  .tf h4 {
    padding-top: 7px;
  }
  .redeem-your-points .card {
    max-height: 215px;
  }
  /* .Multiple-Sports-card .card:hover h4 {
    font-size: var(--20px);
    font-weight: var(--bold);
    color: var(--white);
    margin: 0px;
  } */

  .Multiple-Sports-card .inner-card p {
    margin: 0px;
    padding-top: 15px;
    font-size: var(--14px);
    color: var(--gray);
    font-weight: var(--regular);
  }

  .card_img {
    width: 80px;
    height: 80px;
  }

  .tf h2 {
    font-size: var(--14px);
    font-weight: bold;
    color: var(--black);
  }

  .bg-img {
    background: url(../../../public/images/section-bg.png);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    height: 1250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin: 0px;
    color: #ffffff;
    width: 100%;
    padding: 0px;
  }

  .tf {
    padding-top: 50px;
  }

  .Multiple-Sports {
    padding-bottom: 50px;
  }

  .tf.bg-img {
    padding: 0px;
    margin-bottom: 56px;
  }
  .section h1 {
    font-size: 45px;
  }
  .section_head {
    margin: 20px 0;
  }
  .tf .redeem-your-points .row.g-0 {
    position: absolute;
    bottom: -184px;
  }
}
.Multiple-Sports-card .card {
  height: 100%;
}
.text-1 ul li a span {
  margin-left: 5px;
}
.points-img-box {
  width: 150px;
  height: auto;
}
/*mobile view 767 **/
@media (max-width: 768px) {
  .container {
    width: 100%;
  }

  header button .fa.fa-bars {
    color: var(--orange);
  }

  .btn-icon {
    width: 20px;
    height: 20px;
    padding-right: 5px;
  }

  .navbar-toggler {
    border: 1px solid var(--orange);
    border-radius: 0px;
    padding: 6px 10px;
    transform: skewX(-30deg);
    font-size: var(--14px);
  }

  .fa.fa-bars {
    transform: skewX(30deg);
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }

  .nav-link.active {
    width: min-content;
  }

  .nav-link:hover {
    width: max-content;
  }

  .nav-item {
    padding: 0px 16px;
  }

  .tf h6 {
    font-size: var(--25px);
    font-weight: var(--bold);
    margin: 0px;
    padding: 0px;
  }

  .tf h3 {
    font-size: var(--20px);
    font-weight: var(--regular);
    padding-bottom: 10px;
    margin: 0px;
  }

  .tf p {
    margin: 0px;
    padding-top: 20px;
    font-size: var(--14px);
    color: var(--gray);
    font-weight: var(--regular);
  }

  .new-btn {
    color: var(--white);
    border: none;
    padding: 8px 15px;
    font-size: var(--14px);
    font-weight: var(--bold);
    transform: skewX(-30deg);
    transition: all 0.3s ease-out 0s;
    background-image: linear-gradient(to right, #ff900d, #ff6a0d);
    top: -25px;
    position: relative;
  }

  .btn-black {
    background: var(--black);
    color: var(--white);
    border: none;
    padding: 8px 15px;
    font-size: var(--14px);
    transform: skewX(-30deg);
    transition: all 0.3s ease-out 0s;
    margin-left: 17px;
  }

  header .d-flex {
    padding: 15px 30px;
  }

  .btn-icon {
    width: 20px;
    height: 20px;
    padding-right: 5px;
  }

  .navbar-nav .show .nav-link,
  .navbar-nav .nav-link.active {
    color: var(--orange);
    font-size: var(--14px);
    font-weight: var(--bold);
    transform: skewX(0deg);
  }

  .nav-link.active::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link.active::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::before {
    content: "";
    position: absolute;
    right: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    left: auto;
    background-color: var(--orange);
  }

  .nav-link:hover::after {
    content: "";
    position: absolute;
    left: -13px;
    top: 8px;
    height: 22px;
    width: 2px;
    transform: skewX(-30deg);
    z-index: 1;
    opacity: 1;
    background-image: none;
    bottom: 0;
    border-radius: 0;
    right: auto;
    background-color: var(--orange);
  }

  .nav-link {
    font-size: var(--14px);
    font-weight: var(--regular);
    color: var(--gray);
  }

  footer .text-1 {
    padding-top: 30px;
    padding-left: 0px;
  }

  footer {
    background: var(--dark-black);
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin-top: 50px;
    color: var(--white);
    padding: 0px;
    padding-top: 0px;
    width: 100%;
    padding-top: 50px;
    text-align: center;
  }

  footer h1 {
    font-size: var(--14px);
    font-weight: var(--bold);
    color: var(--white);
    padding-bottom: 10px;
  }

  .text-1 ul li {
    padding-bottom: 5px;
  }

  footer ul li a {
    text-decoration: none;
    font-size: var(--14px);
    line-height: 20px;
    color: var(--gray);
    font-weight: 400;
    padding: 0px;
  }

  footer .copy-right p {
    padding: 25px 0px;
    color: var(--gray);
    font-size: var(--14px);
    font-weight: var(--regular);
    text-align: center;
  }

  footer .form-control {
    display: block;
    width: 100%;
    padding: 10px 15px;
    font-size: var(--14px);
    color: var(--gray);
    background-color: var(--dark-black);
    background-clip: padding-box;
    border: 1px solid var(--border);
    margin-right: 0px !important;
    border-radius: 0px;
  }

  .Multiple-Sports-card .card {
    border: 1px solid var(--border);
    padding: 20px 40px;
    border-radius: 0px;
    transform: skewX(-16deg);
    margin-bottom: 20px;
  }

  .d-flex {
    padding: 5px;
  }

  #navbarNavDropdown ul {
    padding: 5px 0px;
  }

  .banner-text-box {
    text-align: center;
    font-size: var(--60px);
    font-weight: var(--bold);
    margin: 0px;
    padding-top: 70px;
  }
  .home-content {
    margin-bottom: 20px;
  }

  .footer-form a {
    background: var(--white);
    padding: 10px;
    display: flex;
  }

  .banner {
    background: url(../../../public/images/banner.jpg);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin: 0px;
    color: #ffffff;
    width: 100%;
  }

  header {
    padding: 0px 0px;
    background: var(--dark-black);
    position: fixed;
    z-index: 66;
    width: 100%;
    border-bottom: 1px solid var(--black);
  }

  .points-img-box {
    width: auto;
    height: auto;
  }

  .tf h4 {
    font-size: var(--20px);
    font-weight: var(--bold);
    color: var(--black);
    margin: 0px;
    padding-top: 20px;
  }

  .Multiple-Sports-card .card:hover h4 {
    font-size: var(--20px);
    font-weight: var(--bold);
    color: var(--white);
    margin: 0px;
  }

  .Multiple-Sports-card .inner-card p {
    margin: 0px;
    padding-top: 15px;
    font-size: var(--14px);
    color: var(--gray);
    font-weight: var(--regular);
  }

  .card_img {
    width: 80px;
    height: 80px;
  }

  .tf h2 {
    font-size: var(--14px);
    font-weight: bold;
    color: var(--black);
  }

  .bg-img {
    background: url(../../../public/images/section-bg.png);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    height: 2900px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    margin: 0px;
    color: #ffffff;
    width: 100%;
    padding: 0px;
  }

  .tf.bg-white-img {
    padding-top: 0px;
  }

  .tf {
    padding-top: 50px;
  }

  .Multiple-Sports {
    padding-bottom: 50px;
    padding-top: 30px;
  }

  .tf .bg-img {
    padding: 0px;
  }

  header img {
    width: 100px;
  }

  .navbar-nav.mx-auto.mr-auto.d-lg-flex {
    padding-top: 10px;
  }

  .tf.bg-img {
    padding-top: 0px;
    height: auto;
  }
  .tf .redeem-your-points {
    padding-bottom: 10px;
  }

  .money-img-box {
    padding-bottom: 20px;
  }

  .casino-img-box {
    padding-top: 30px;
  }

  .padding-b-50px {
    padding-bottom: 20px;
  }

  .redeem-your-points .card {
    border: 1px solid var(--border);
    border-radius: 0px;
    transform: skewX(-16deg);
    padding: 50px 20px;
    display: flex;
    margin-bottom: 20px;
  }

  .tf h2 {
    font-size: var(--20px);
    font-weight: bold;
    color: var(--black);
  }

  .tf .redeem-your-points .row.g-0 {
    position: relative;
    bottom: auto;
    display: flex;
  }
  .tf .redeem-your-points .row.g-0 .col-md-3 {
    width: 50%;
  }
  .redeem-your-points .card {
    max-height: 223px;
    transform: skewX(-5deg);
  }
  .redeem-your-points .inner-card {
    transform: skewX(5deg);
    margin: auto;
  }
  .points-img-box img {
    width: 100px;
    height: 64px;
    object-fit: cover;
  }
  .section_heading .heading_line {
    height: 2px;
  }
  .section h1 {
    font-size: 35px;
  }
  .section_head {
    margin: 10px 0;
  }
}
